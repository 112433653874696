import {
	AggregatedPositionFragment as AggregatedPosition,
	CallTransactionFragment as CallTransaction,
	CashEventType,
	DistributedTransactionFragment as DistributedTransaction,
	GetTransactionsQuery,
	SubscriptionTradeFragment as SubscriptionTrade,
	TradeType,
	TransfertTransactionFragment as TransfertTransaction,
	GetCashEventWorkflowsByShareClassesQuery,
	CashEventWorkflowState
} from "~/generated/operations-airdistrib"
import {
	GetDocumentsFromAfQuery,
	GetNotificationsFromAfQuery,
	GetProductFromAfQuery,
	GetProductsFromAfQuery,
	AttributeTypeFormat,
	IndicatorFragment as Indicator,
	ShareClassFragment as ShareClass,
	TaskStatus,
	TaskType,
  NotificationStatus
} from "~/generated/operations-airfund"
import {
	GetInvestorDocumentsFromAwQuery,
} from "~/generated/operations-airwealth"
import { State as DocumentsState } from '~/store/documents'
import { State as HomeState } from '~/store/home'
import { State as ProductsState } from '~/store/products'
import { State as CompanyState } from '~/store/company'
import { convertEpochDayToDate, convertTimestampDate, convertUnixDate, DateFormat, formatDistanceToNowLocalized } from '~/utils/dates'
import { Currency, formatCurrency, formatNumber, getCurrencySymbol } from "~/utils/numbers"
import {
	FundItem,
	IndicatorItem,
	InvestmentsDocument,
	NoticeDocument,
	NotificationType,
	ShareItem,
	Subscription
} from '~/types/domain/domain'
import { currentCompany } from '~/composables/repository'
import { compareDesc } from 'date-fns'
import { i18n } from '~/utils/i18n'
import { cleanFileName } from "~/utils/documents"
import { slugify } from "~/utils/strings"

export interface CustomFundItem {
  id: string
  name: string
  umbrellaName: string
  categories: { name: string, id: string }[]
  shares: CustomShareItem[]
  assetClass: FundItem['assetClass'] | null
  currency: string
}

interface CustomShareItem {
	id: string
	name: string
	currency: Currency
	lastNavDate: number
}

export interface CustomContactItem {
  id: string
  firstName?: string
  lastName?: string
  title?: string
  workPhone?: string
  email: string
  categories: string[]
}

export const getFundsFromShareClasses = (shareClasses: ShareClass[]): CustomFundItem[] => {
  const fundsDict: Record<string, any> = {}
  shareClasses.forEach(share => {
    const fund = share?.fund
    const obj = {
      id: share.id,
      name: share.fullShareClassName,
      currency: share.currency,
      lastNavDate: share?.metrics?.lastAdjustedNavDate
    }

    if (fund) {
      const code = `${fund.id}-${obj.currency}`
      if (fundsDict[code]) {
        fundsDict[code].shares.push(obj)
      } else {
        fundsDict[code] = {
          id: fund.id,
          name: fund.legalFundNameOnly,
          umbrellaName: fund.umbrellaName,
          categories: fund.categories.map(c => c.tag),
          shares: [obj],
          assetClass: fund?.privateEquity?.assetClass || null,
          currency: obj.currency
        }
      }
    }
  })
  return Object.values(fundsDict)
}

export const getCategoriesFromFunds = (funds: CustomFundItem[], filteringMethod: CompanyState['settings']['FUNDS_CATEGORIZATION_METHOD']): { name: string, id: string | null }[] => {
  let categories = []
  switch (filteringMethod) {
    case 'CURRENCY':
      categories = funds.map(fund => fund.currency)
      categories = [...new Set(categories)].filter(c => c)
      categories = categories.map(currency => ({ name: currency, id: currency }))
      break
    case 'ASSET_CLASS':
      categories = funds.map(fund => fund.assetClass)
      categories = [...new Set(categories)].filter(c => c)
      categories = categories.map(assetClass => {
        const code = assetClass?.toLowerCase()
        return {
          name: i18n.t(`products.asset_classes.${code}`),
          id: assetClass
        }
      })
      break
    default:
      categories = funds.map(fund => fund.categories).flat()
      categories = categories.filter((category, index, self) => self.findIndex(c => c.id === category.id) === index)
      break
  }
	categories = categories.map(category => ({ name: category.name, id: category.id ?? null }));
  categories = [...categories, { name: i18n.t('words.uncategorized'), id: null }]
  categories = categories.sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }))

  return categories
}

export const categoryFundToFundItem = ({ fund, aggregatedPositions, subscriptionTrades, category }: { fund: CustomFundItem, aggregatedPositions: AggregatedPosition[], subscriptionTrades: SubscriptionTrade[], category: string }): FundItem => {
	let shares = fund.shares.map(share => {
		const agregPosition = aggregatedPositions.find(
			({ airFundKeys }) => airFundKeys?.shareClass === share.id
		)
		let operations = subscriptionTrades.filter(
			({ airFundKeys }) => airFundKeys?.shareClass === share.id
		).sort((a, b) => (a?.date as number) - (b?.date as number))
		const operationDates = operations.map(operation => operation?.date).filter(date => date) as number[]
		const operationDate = operationDates.length > 0 ? Math.min(...operationDates) : null
		operations = operations.filter(operation => operation?.tradeType === TradeType.Subscription)

		const currencySymbol = getCurrencySymbol(share.currency)
		return {
			id: share.id,
			name: share.name,
			lastDate: {
				value: convertEpochDayToDate(share.lastNavDate, DateFormat.YYYYMMDD),
				display: convertEpochDayToDate(share.lastNavDate),
				textDisplay: convertEpochDayToDate(share.lastNavDate, DateFormat.DMMMMYYYY)
			},
			currency: share.currency as Currency,
			indicators: agregPosition ? [
				investmentsToIndicator(agregPosition, currencySymbol),
				distributedToIndicator(agregPosition, currencySymbol),
				valuationToIndicator(agregPosition, currencySymbol),
				calledToIndicator(agregPosition, currencySymbol),
				amountToCallToIndicator(agregPosition, currencySymbol),
				amountCalledPctToIndicator(agregPosition)
			] : [],
			transactions: {
				calls: [],
				totalSubscription: agregPosition ? afTotalSubscriptionToDomain(agregPosition, operationDate, currencySymbol) : undefined,
				subscriptions: afSubscriptionsToDomain(operations, currencySymbol),
				distributions: [],
				redemptionsAndTransfers: []
			},
			positions: []
		}
	})
	shares = shares.filter(share => {
		return share.transactions.totalSubscription?.engagement.value
	})
	shares = shares.sort((a, b) => a.name.localeCompare(b.name))

	return {
		id: fund.id,
		name: fund.name,
		category,
		tags: [],
		shares: shares as FundItem['shares'],
		assetClass: fund.assetClass as FundItem['assetClass']
	}
}

export const investmentsToIndicator = (agregPosition: AggregatedPosition, currencySymbol: string): IndicatorItem => {
	return {
		code: 'investments',
		value: {
			value: agregPosition?.engaged as number,
			display: formatCurrency((agregPosition?.engaged as number), currencySymbol, { decimals: 2, fillDecimals: true })
		}
	}
}
export const calledToIndicator = (agregPosition: AggregatedPosition, currencySymbol: string): IndicatorItem => {
	return {
		code: 'amountCalled',
		value: {
			value: agregPosition?.called as number,
			display: formatCurrency((agregPosition?.called as number), currencySymbol, { decimals: 2, fillDecimals: true })
		}
	}
}
export const amountToCallToIndicator = (agregPosition: AggregatedPosition, currencySymbol: string): IndicatorItem => {
	return {
		code: 'amountToCall',
		value: {
			value: agregPosition?.amountToCall as number,
			display: formatCurrency((agregPosition?.amountToCall as number), currencySymbol, { decimals: 2, fillDecimals: true })
		}
	}
}
export const amountCalledPctToIndicator = (agregPosition: AggregatedPosition): IndicatorItem => {
	const amountToCallPct = agregPosition?.percentage * 100 || null
	return {
		code: 'amountCalledPct',
		value: {
			value: amountToCallPct,
			display: formatNumber(amountToCallPct, { append: '%', decimals: 2, fillDecimals: true })
		}
	}
}
export const distributedToIndicator = (agregPosition: AggregatedPosition, currencySymbol: string): IndicatorItem => {
	return {
		code: 'distributed',
		value: {
			value: agregPosition?.distributed as number,
			display: formatCurrency((agregPosition?.distributed as number), currencySymbol, { decimals: 2, fillDecimals: true })
		}
	}
}
export const valuationToIndicator = (agregPosition: AggregatedPosition, currencySymbol: string): IndicatorItem => {
	return {
		code: 'valuation',
		value: {
			value: agregPosition?.valorization as number,
			display: formatNumber((agregPosition?.valorization as number), { append: currencySymbol, decimals: 2, fillDecimals: true })
		}
	}
}

export const multipleToIndicator = (indicators: Indicator[]): IndicatorItem => {
	const indicator = indicators.find(indicator => indicator.value?.type.key === 'MOIC')

	return {
		code: 'multiple',
		value: getIndicatorValue(indicator!)
	}
}

export const targetMultipleToIndicator = (indicators: Indicator[]): IndicatorItem => {
	const indicator = indicators.find(indicator => indicator.value?.type.key === 'TARGET_MOIC')

	return {
		code: 'target_multiple',
		value: getIndicatorValue(indicator!)
	}
}

export const triToIndicator = (indicators: Indicator[]): IndicatorItem => {
	const indicator = indicators.find(indicator => indicator.value?.type.key === 'IRR')

	return {
		code: 'TRI',
		value: getIndicatorValue(indicator!)
	}
}

export const targetTriToIndicator = (indicators: Indicator[]): IndicatorItem => {
	const indicator = indicators.find(indicator => indicator.value?.type.key === 'TARGET_IRR')

	return {
		code: 'target_TRI',
		value: getIndicatorValue(indicator!)
	}
}

export const dpiToIndicator = (indicators: Indicator[]): IndicatorItem => {
	const indicator = indicators.find(indicator => indicator.value?.type.key === 'DPI')

	return {
		code: 'DPI',
		value: getIndicatorValue(indicator!)
	}
}
export const rvpiToIndicator = (indicators: Indicator[]): IndicatorItem => {
	const indicator = indicators.find(indicator => indicator.value?.type.key === 'RVPI')

	return {
		code: 'RVPI',
		value: getIndicatorValue(indicator!)
	}
}
export const tvpiToIndicator = (indicators: Indicator[]): IndicatorItem => {
	const indicator = indicators.find(indicator => indicator.value?.type.key === 'TVPI')

	return {
		code: 'TVPI',
		value: getIndicatorValue(indicator!)
	}
}
export const rentalYieldToIndicator = (indicators: Indicator[]): IndicatorItem => {
	const indicator = indicators.find(indicator => indicator.value?.type.key === 'RE_YIELD')

	return {
		code: 'RENTAL_YIELD',
		value: getIndicatorValue(indicator)
	}
}
export const occupancyRateToIndicator = (indicators: Indicator[]): IndicatorItem => {
	const indicator = indicators.find(indicator => indicator.value?.type.key === 'TOF')

	return {
		code: 'OCCUPANCY_RATE',
		value: getIndicatorValue(indicator)
	}
}

export const getIndicatorValue = (indicator?: Indicator): IndicatorItem['value'] => {
	try {
		let value = indicator?.value?.stringValue || indicator?.value?.intValue || indicator?.value?.doubleValue
		const type = indicator?.value?.type?.format

		switch(type) {
			case AttributeTypeFormat.AttributeTypeFormatPercent:
				if (typeof value === 'string') {
					value = parseFloat(value)
				}
				return {
					value: value! * 100,
					display: formatNumber(value! * 100, { append: '%', decimals: 2 })
				}
			case AttributeTypeFormat.AttributeTypeFormatMultiplier:
				if (typeof value === 'string') {
					value = parseFloat(value)
				}
				return {
					value: value as number,
					display: formatNumber(value as number, { append: 'x', decimals: 2 })
				}
			case AttributeTypeFormat.AttributeTypeFormatText:
				return {
					value: null,
					display: value as string
				}
			default:
				return {
					value: null,
					display: value!.toString()
				}
		}
	} catch {
		return {
			value: null,
			display: '-'
		}
	}
}

export const afTotalSubscriptionToDomain = (agregPosition: AggregatedPosition, date: number | null = null, currencySymbol: string): Subscription => {
	return {
		date: {
			value: convertUnixDate(date as number, DateFormat.YYYYMMDD) as string,
			display: convertUnixDate(date as number) as string
		},
		engagement: {
			value: agregPosition?.engaged as number,
			display: formatCurrency((agregPosition?.engaged as number), currencySymbol, { decimals: 2, fillDecimals: true })
		}
	}
}

export const afSubscriptionsToDomain = (arr: SubscriptionTrade[], currencySymbol: string): ShareItem['transactions']['subscriptions'] => {
	let subscriptionTransactions: ShareItem['transactions']['subscriptions'] = []

	arr.forEach(subscriptionObject => {
		const subscription = {
			date: {
				value: convertUnixDate(subscriptionObject?.date as number, DateFormat.YYYYMMDD),
				display: convertUnixDate(subscriptionObject?.date as number)
			},
			engagement: {
				value: subscriptionObject?.commitment,
				display: formatCurrency((subscriptionObject?.commitment as number), currencySymbol, { decimals: 2, fillDecimals: true })
			}
		} as ShareItem['transactions']['subscriptions'][number]
		subscriptionTransactions.push(subscription)
	})
	subscriptionTransactions = subscriptionTransactions.sort((a, b) => {
		return compareDesc(new Date(a.date.value), new Date(b.date.value))
	})

	return subscriptionTransactions
}

export const afCallsToDomain = (arr: CallTransaction[], aggregatedTransfersIn: GetTransactionsQuery['aggregatedTransfersIn'], share: ShareItem): ShareItem['transactions']['calls'] => {
	let callTransactions: ShareItem['transactions']['calls'] = []
	const callObjects = arr.filter(
		({ statisticTrade }) => statisticTrade?.airFundKeys?.shareClass === share.id
	)
	aggregatedTransfersIn = aggregatedTransfersIn.filter(({ statisticTrade, cashEventRow }) => {
		return statisticTrade?.airFundKeys?.shareClass === share.id && (cashEventRow?.cashEventType === CashEventType.Call || cashEventRow?.cashEventType === CashEventType.ReturnOfCall)
	})
	callObjects.push(...aggregatedTransfersIn as CallTransaction[])

	const currencySymbol = getCurrencySymbol(share.currency)
	const engaged = share?.transactions?.totalSubscription?.engagement?.value || 0

	callObjects.forEach(callObject => {
		const { cashEventRow, statisticTrade } = callObject
		const code = (cashEventRow?.cashEventType === CashEventType.ReturnOfCall) ? 'return_of_call' : 'call'
		const date = Math.max(cashEventRow?.effectiveDate!, statisticTrade?.valueDate!) || cashEventRow?.effectiveDate

		let percentage: number | null
		try {
			percentage = (cashEventRow?.amount! / engaged) * 100
		} catch {
			percentage = null
		}
		let description = cashEventRow?.description
		if ([TradeType.TransfertOut, TradeType.TransfertIn].includes(statisticTrade?.tradeType as TradeType) && statisticTrade?.valueDate! === cashEventRow?.effectiveDate!) {
			description = i18n.t('trade_types.transfertin_description', { date: convertUnixDate(date!) })
		}

		if (cashEventRow?.cashEventType === CashEventType.Call && cashEventRow?.adjustment) {
			description = i18n.t('trade_types.call_adjustment')
		}

		const callTransaction = {
			amount: {
				value: cashEventRow?.amount,
				display: formatCurrency(cashEventRow?.amount!, currencySymbol, { decimals: 2, fillDecimals: true }),
			},
			date: {
				value: convertUnixDate(date!, DateFormat.YYYYMMDD),
				display: convertUnixDate(date!)
			},
			pct: {
				value: percentage,
				display: formatNumber(percentage, { append: '%', decimals: 2, fillDecimals: true }),
			},
			description,
			notificationId: cashEventRow?.airFundKeys?.task,
			code
		}
		callTransactions.push(callTransaction as ShareItem['transactions']['calls'][number])
	})
	callTransactions = callTransactions.sort((a, b) => {
		return compareDesc(new Date(a.date.value), new Date(b.date.value))
	})
	return callTransactions
}

export const afDistributionsToDomain = (arr: DistributedTransaction[], aggregatedTransfersIn: GetTransactionsQuery['aggregatedTransfersIn'], share: ShareItem): ShareItem['transactions']['distributions'] => {
	let distributionTransactions: ShareItem['transactions']['distributions'] = []
	const distributionObjects = arr.filter(
		({ statisticTrade }) => statisticTrade?.airFundKeys?.shareClass === share.id
	)
	aggregatedTransfersIn = aggregatedTransfersIn.filter(({ statisticTrade, cashEventRow }) => {
		return statisticTrade?.airFundKeys?.shareClass === share.id && (cashEventRow?.cashEventType === CashEventType.Distribution || cashEventRow?.cashEventType === CashEventType.ReCall )
	})
	distributionObjects.push(...aggregatedTransfersIn as DistributedTransaction[])
	const currencySymbol = getCurrencySymbol(share.currency)

	distributionObjects.forEach(distributionObject => {
		const { cashEventRow, statisticTrade } = distributionObject
		const code = (cashEventRow?.cashEventType === CashEventType.ReCall) ? 'distribution_recall' : 'distribution'
		const date = Math.max(cashEventRow?.effectiveDate!, statisticTrade?.valueDate!) || cashEventRow?.effectiveDate

		let percentage: number | null
		try {
			const totalAmountCalled = share.indicators.find(indicator => indicator.code === 'amountCalled')?.value?.value
			percentage = totalAmountCalled ? ((cashEventRow?.amount! / totalAmountCalled) * 100) : null
		} catch {
			percentage = null
		}

		const distributionTransaction = {
			share: share.name,
			code,
			description: cashEventRow?.description,
			amount: {
				value: cashEventRow?.amount,
				display: formatCurrency(cashEventRow?.amount!, currencySymbol, { decimals: 2, fillDecimals: true })
			},
			amountPercentage: {
				value: percentage,
				display: formatNumber(percentage, { append: '%', decimals: 2, fillDecimals: true })
			},
			shareAmount: {
				value: cashEventRow?.numberOfShares,
				display: formatNumber(cashEventRow?.numberOfShares!)
			},
			date: {
				value: convertUnixDate(date!, DateFormat.YYYYMMDD),
				display: convertUnixDate(date!)
			},
			nav: {
				value: statisticTrade?.navAd?.valuationNAV,
				display: formatCurrency(statisticTrade?.navAd?.valuationNAV!, currencySymbol)
			},
			notificationId: cashEventRow?.airFundKeys?.task
		}
		distributionTransactions.push(distributionTransaction as ShareItem['transactions']['distributions'][number])
	})
	distributionTransactions = distributionTransactions.sort((a, b) => {
		return compareDesc(new Date(a.date.value), new Date(b.date.value))
	})
	return distributionTransactions
}

export const afRedemptionsToDomain = (arr: TransfertTransaction[], share: ShareItem, prioritizeGrossAmount: boolean): ShareItem['transactions']['redemptionsAndTransfers'] => {
	let redemptionTransactions : ShareItem['transactions']['redemptionsAndTransfers'] = []
	const redemptionObjects = arr.filter(
		({ airFundKeys }) => airFundKeys?.shareClass === share.id
	)
	const currencySymbol = getCurrencySymbol(share.currency)

	redemptionObjects.forEach(redemptionObject => {
		let tradeValue = redemptionObject?.commitment
		if (prioritizeGrossAmount && !!redemptionObject.commitment) {
			tradeValue = redemptionObject.grossAmount
		}

		const amount = (redemptionObject?.tradeType === TradeType.TransfertOut) ? -Math.abs(tradeValue!) : tradeValue!
		let description = i18n.t(`trade_types.${redemptionObject.tradeType?.toLowerCase()}`)
		if (redemptionObject?.description) {
			description = description + ` - ${redemptionObject?.description}`
		}

		const redemption: ShareItem['transactions']['redemptionsAndTransfers'][0] = {
			share: share.name,
			type: redemptionObject.tradeType as string,
			description,
			date: {
				value: convertUnixDate(redemptionObject?.valueDate!, DateFormat.YYYYMMDD)!,
				display: convertUnixDate(redemptionObject?.valueDate!)!
			},
			shareAmount: {
				value: redemptionObject.numberOfShares!,
				display: formatNumber(redemptionObject.numberOfShares!)
			},
			unitPrice: {
				value: redemptionObject?.navAd?.valuationNAV!,
				display: formatCurrency(redemptionObject?.navAd?.valuationNAV!, currencySymbol)
			},
			amount: {
				value: amount,
				display: formatCurrency(amount, currencySymbol, { decimals: 2, fillDecimals: true })
			}
		}
		redemptionTransactions.push(redemption)
	})
	redemptionTransactions = redemptionTransactions.sort((a, b) => {
		return compareDesc(new Date(a.date.value), new Date(b.date.value))
	})
	return redemptionTransactions
}

export const shareDocumentsToDomain = (share: GetDocumentsFromAfQuery['shareClasses'][0]): InvestmentsDocument[] => {
	let items = share.documents || []
	const fundDocuments = share.fund?.documents || []
	items = [...items, ...fundDocuments]
	items = items.filter(item => item?.partnersOnly !== true)

	return items.map(item => {
		const year = convertEpochDayToDate(item?.date!, DateFormat.YYYY)

		return {
			id: item.id,
			ressourceId: item.resource?.id,
			title: cleanFileName(item?.name || ''),
			file: cleanFileName(item.resource?.fileName || ''),
			type: item?.documentType,
			date: {
				value: convertEpochDayToDate(item?.date!, DateFormat.YYYYMMDD),
				display: convertEpochDayToDate(item?.date!)
			},
			year,
			fund: {
				id: share?.fund?.id,
				name: share?.fund?.name,
				categoryId: share?.fund?.categories?.[0]?.tag?.id || null
			},
			share: {
				id: share.id,
				name: share.name
			}
		} as InvestmentsDocument
	})
}

export const investorDocumentsToDomain = (investor: GetDocumentsFromAfQuery['investor'], funds: FundItem[]): InvestmentsDocument[] => {
	const items = investor?.documents || []

	return items.map(item => {
		const year = convertEpochDayToDate(item?.date, DateFormat.YYYY)
		const fund = funds.find(fund => fund.id === item.relationKeys?.fund)
		const share = fund?.shares?.find(share => share.id === item.relationKeys?.shareClass)

		return {
			id: item.id,
			ressourceId: item.resource?.id,
			title: cleanFileName(item?.investorDocument?.name || ''),
			file: cleanFileName(item.resource?.fileName || ''),
			type: item?.investorDocument?.documentType,
			date: {
				value: convertEpochDayToDate(item?.date, DateFormat.YYYYMMDD),
				display: convertEpochDayToDate(item?.date)
			},
			year,
			fund: {
				id: fund?.id,
				name: fund?.name,
				categoryId: fund?.category || null
			},
			share: {
				id: share?.id,
				name: share?.name
			}
		} as InvestmentsDocument
	})
}

export const noticesDocumentsToDomain = (
  notices: GetCashEventWorkflowsByShareClassesQuery['cashEventWorkflows'][0],
  funds: FundItem[]
): NoticeDocument[] => {
  return notices.map(notice => {
    const fund = funds.find(f => f.shares.some(s => s.id === notice.airFundKeys.shareClass));
    const share = fund?.shares.find(s => s.id === notice.airFundKeys.shareClass);
		const lastReceivedDate = (notice?.workflowAudit ?? []).filter(o => {
			return o?.newState == CashEventWorkflowState.Sent
		}).sort((a, b) => {
			return (b.timestamp ?? 0) - (a.timestamp ?? 0)
		}).find(() => true)?.timestamp ?? null
		const lastPaidDate = (notice?.workflowAudit ?? []).filter(o => {
			return o?.newState == CashEventWorkflowState.Paid
		}).sort((a, b) => {
			return (b.timestamp ?? 0) - (a.timestamp ?? 0)
		}).find(() => true)?.timestamp ?? null
		const lastDate = lastReceivedDate || lastPaidDate
		const titleDate = convertUnixDate(notice.cashEventDate, DateFormat.YYYYMMDD)
		const type = notice.cashEventType.toUpperCase()
		const typeTranslated = i18n.t(`documents.types.notice.${type.toUpperCase()}`)
		const title = slugify(`${titleDate}-${currentCompany()}-${share.name}-${typeTranslated}`) + '.pdf';

    return {
      id: notice.id,
      ressourceId: notice.resourceId,
      title,
			file: title,
      type,
      date: {
				value: formatDate(lastDate, DateFormat.YYYYMMDD),
				display: lastDate ? formatDate(lastDate) : '-'
			},
			cashEventDate: {
				value: convertUnixDate(notice.cashEventDate as number, DateFormat.YYYYMMDD) as string,
				display: convertUnixDate(notice.cashEventDate as number) as string
			},
			status: notice.state?.toUpperCase(),
      year: convertUnixDate(notice.creationDate, DateFormat.YYYY),
      fund: {
        id: fund?.id || '',
        name: fund?.name || '',
        categoryId: fund?.category || ''
      },
      share: {
        id: share?.id || '',
        name: share?.name || ''
      }
    } as NoticeDocument;
  });
}

export const userDocumentsToDomain = (investor: GetInvestorDocumentsFromAwQuery['me']['investorByAfId']): DocumentsState['user'] => {
	const userDocuments: DocumentsState['user']['documents'] = []
	const userSubscriptions: DocumentsState['user']['subscriptions'] = []

	if (investor) {
    const documents = investor.documents.sort((a, b) => b?.publicationDate! - a?.publicationDate!)
    documents.forEach(doc => {
      userDocuments.push({
        id: doc.id,
        investorId: investor?.id,
        ressourceId: doc.hash,
        type: doc?.type?.code!,
        label: doc?.type?.label!,
        file: doc.name,
        date: {
          value: doc?.publicationDate?.toString()!,
          display: convertEpochDayToDate(doc?.publicationDate!)!
        }
      })
    })

		let subscriptions = investor?.subscriptions || []
		subscriptions.forEach(subscription => {
			const signed = subscription.subscriptionDocuments.map(obj => {
				return {
					id: obj?.document?.id,
					ressourceId: obj?.document?.hash,
					file: obj?.document?.name,
					date: {
						value: obj?.document?.publicationDate,
						display: convertEpochDayToDate(obj?.document?.publicationDate!) ?? '-'
					}
				}
			}) as unknown as DocumentsState['user']['subscriptions'][number]['documents']['signed']

			const supporting: DocumentsState['user']['subscriptions'][number]['documents']['supporting'] = []
			subscription.documentChecklist.forEach(obj => {
				const doc = documents.find(doc => doc?.type?.code === obj?.documentType?.code)

				if (doc) {
					supporting.push({
						id: doc.id,
						ressourceId: doc.hash,
						file: doc.name,
						date: {
							value: doc?.publicationDate?.toString()!,
							display: convertEpochDayToDate(doc?.publicationDate!) ?? '-'
						}
					})
				}
			})

			if (signed.length > 0) {
				let date = {
					value: '',
					display: '-'
				}
				try {
					date = {
						value: convertTimestampDate(subscription?.date!, DateFormat.YYYYMMDD)!,
						display: convertTimestampDate(subscription?.date!) ?? '-'
					}
				} catch {}

				userSubscriptions.push({
					id: subscription.id,
					fund: {
						name: subscription?.product?.name,
						currency: subscription?.product?.product?.cotationCurrency as Currency || 'EUR',
					},
					date,
					engaged: subscription?.amount,
					documents: {
						signed,
						supporting
					}
				})
			}
		})
	}

  return {
    documents: userDocuments,
    subscriptions: userSubscriptions
  }
}

export const notificationsToDomain  = (notificationsData: GetNotificationsFromAfQuery['notifications']): HomeState['notifications'] => {
  return notificationsData.map((notification) => {
		return {
			id: notification.id,
			description: notification.message.body,
			type: NotificationType[notification.category as keyof typeof NotificationType],
			date: {
				value: notification.date,
				display: formatDistanceToNowLocalized(notification.date) ?? '',
				textDisplay: convertTimestampDate(notification.date, DateFormat.DMMMMYYYY_HHMM) ?? undefined,
			},
			active: notification.status === NotificationStatus.New,
		}
  })
}

export const tasksToDomain = (tasksData: GetNotificationsFromAfQuery['tasks']): HomeState['notifications'] => {
  tasksData = tasksData.filter(notifData => [TaskType.Call, TaskType.Distribution, TaskType.ReturnOfCall].includes(notifData.type))

  let notifications = tasksData.map(notification => {
    return {
      id: notification.id,
      type: NotificationType[notification.type as keyof typeof NotificationType],
      description: notification?.description,
      active: notification.status !== TaskStatus.Done,
      date: {
        value: convertTimestampDate(notification.date, DateFormat.YYYYMMDD),
        display: convertTimestampDate(notification.date)
      }
    } as HomeState['notifications'][number]
  })

  notifications = notifications.sort((a, b) => {
    return compareDesc(new Date(a.date.value), new Date(b.date.value))
  })

  return notifications
}

export const fundCaracteristicsToDomain = (fund: GetProductFromAfQuery['fund'] | GetProductsFromAfQuery['funds'][0]): ProductsState['products'][0]['caracteristics'] => {
	const sectors = fund?.privateEquity?.sectors || []
	const investmentStrategies = fund?.privateEquity?.investmentStrategies || []
	const investmentAreas = fund?.investmentAreas || []
	const assetClass = fund?.privateEquity?.assetClass || null
	const fiscalities = fund?.privateEquity?.fiscalities || []
	const aumTarget = fund?.privateEquity?.aumTarget || null
	const eligibilities = fund?.offerTypeEligibilities || []
	const sfdr = fund?.sfdr || null
	const lei = fund?.leiOfFund || null
	const closingDate = fund?.privateEquity?.closingDate || null
	const fundLaunchdate = convertUnixDate(fund?.fundLaunchDate!) || null

	return [
		{
			code: 'sectors',
			value: sectors.map(code => i18n.t(`products.sectors.${code.toLowerCase()}`)).join(', ') || '-'
		},
		{
			code: 'investment_strategies',
			value: investmentStrategies.map(code => i18n.t(`products.investment_strategies.${code.toLowerCase()}`))
		},
		{
			code: 'investment_areas',
			value: investmentAreas.map(code => i18n.t(`products.investment_areas.${code.toLowerCase()}`)).join(', ') || '-'
		},
		{
			code: 'asset_class',
			value: assetClass || '',
		},
		{
			code: 'fiscalities',
			value: fiscalities.map(code => i18n.t(`products.fiscalities.${code.toLowerCase()}`)).join(', ') || '-'
		},
		{
			code: 'aum_target',
			value: formatCurrency(aumTarget!, '€')
		},
		{
			code: 'eligibilities',
			value: eligibilities.join(', ') || '-'
		},
		{
			code: 'sfdr',
			value: sfdr || '-'
		},
		{
			code: 'lei',
			value: lei || '-'
		},
		{
			code: 'closing_date',
			value: convertUnixDate(closingDate!) || '-'
		},
		{
			code: 'fund_launch_date',
			value: fundLaunchdate || '-'
		}
	]
}

export const formatCaracteristic = (caracteristic: number | null): string => {
	if (typeof caracteristic === 'number') {
		if (caracteristic >= 0.00001) {
			return `${caracteristic}%`
		}
		return '0%'
	}

	return '-'
}

export const shareCaracteristicsToDomain = (productShare: GetProductFromAfQuery['fund']['shareClasses'][0]): ProductsState['products'][0]['shares'][0]['caracteristics'] => {
	const minimalInitialSubscription = productShare?.executionData?.minimalInitialSubscriptionInAmount || null
	const subscriptionFeeApplied = productShare?.fees?.subscriptionFeeApplied || null
	const redemptionFeeApplied = productShare?.fees?.redemptionFeeApplied || null
	const managementFeeApplied = productShare?.fees?.managementFeeApplied || null
	const hurdleRate = productShare?.fees?.hurdleRate || null
	const priipsCarriedInterest = productShare?.regulatory?.priipsCarriedInterest || null
	const valuationFrequency = productShare?.valuationFrequency || null
	const shareClassLifecycle = productShare?.shareClassLifecycle?.replace(' ', '_') || null
	return [
		{
			code: 'minimal_initial_subscription',
			value: formatCurrency(minimalInitialSubscription, '€')
		},
		{
			code: 'subscription_fee_applied',
			value: formatCaracteristic(subscriptionFeeApplied)
		},
		{
			code: 'redemption_fee_applied',
			value: formatCaracteristic(redemptionFeeApplied)
		},
		{
			code: 'management_fee_applied',
			value: formatCaracteristic(managementFeeApplied)
		},
		{
			code: 'hurdle_rate',
			value: hurdleRate ? hurdleRate + '%' : '-'
		},
		{
			code: 'priips_carried_interest',
			value: formatCaracteristic(priipsCarriedInterest)
		},
		{
			code: 'valuation_frequency',
			value: valuationFrequency ? i18n.t(`products.valuation_frequency.${valuationFrequency.toLowerCase()}`) : '-'
		},
		{
			code: 'share_class_lifecycle',
			value: shareClassLifecycle ? i18n.t(`products.life_cycle.${shareClassLifecycle}`) : '-'
		},
	]
}
